import React, { useState, useContext } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/AuthContext";
import { auth } from "../../fireconfig";

import "./login.css";


const Login = () => {
    const [error, setError] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    const {dispatch} = useContext(AuthContext);

    const handleLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password) 
        .then((userCredential) => {
            const user = userCredential.user;
            dispatch({type:"LOGIN", payload:user});
            navigate("/dev");
        })
        .catch((error) => {
            setError(true);
            const errorCode = error.code;
            setErrorMsg(error.message);
        });
    }

    return (
        <div className="form">
            <form onSubmit={handleLogin}>
                <input type="email" placeholder="email" onChange={e=>setEmail(e.target.value)}/>
                <input type="password" placeholder="password" onChange={e=>setPassword(e.target.value)}/>
                <button type="submit">Login</button>
                {error && <span>{errorMsg}</span>}
            </form>
        </div>
    )
}

export default Login;