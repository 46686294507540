import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import Scrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';

import NavBar from "../../../components/navbar/NavBar";

import "./DownInTheDice.css";

gsap.registerPlugin(ScrollTrigger);

function DownInTheDice() {

    //PRELOADING
    let loadingScreenLeftRef = useRef(null);
    let loadingScreenRightRef = useRef(null);

    // create list of refs to animate based on screen location
    let revealRefs = useRef([]);
    revealRefs.current = [];
    const addToRefs = (el) => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    }

    useEffect(() => {
        //const tl = gsap.timeline();
        gsap.ticker.lagSmoothing();
        gsap.to(loadingScreenLeftRef.current, {width: 0, duration: 1, ease:'power1.out', onComplete: function(){ BuildTextReveal()}}).delay(2)
        gsap.to(loadingScreenRightRef.current, {width: 0, duration: 1, ease:'power1.out'}).delay(2);


        function BuildTextReveal(){
            revealRefs.current.forEach((el, index) => {
                gsap.fromTo(el, 
                    {opacity: 0, y: -20, ease: 'power3.inOut'}, 
                    {opacity: 1, y: 0, ease: 'power3.inOut', duration: 1, 
                        scrollTrigger: {trigger: el, id: `section-${index+1}`, start: 'top bottom', toggleActions: 'play reset play reset', markers: false}
                    }
                )
            })
        }


    }, [])

    // SCROLL
    const myScrollbar = useRef(Scrollbar);

    const fixedElem = useRef(null);

    useEffect(() => {
        Scrollbar.use(OverscrollPlugin);
        var bodyScrollBar = Scrollbar.init(document.body, {
            damping: 0.07,
            plugins: { overscroll: {} | false },
        });
        myScrollbar.current = bodyScrollBar;
        // fixed element
        bodyScrollBar.addListener(function(status) {
            fixedElem.current.style.top = status.offset.y + 'px';
        })
        //
        bodyScrollBar.setPosition(0, 0);
        bodyScrollBar.track.xAxis.element.remove();
        ScrollTrigger.scrollerProxy("body", {
            scrollTop(value) {
              if (arguments.length) {
                bodyScrollBar.scrollTop = value;
              }
              return bodyScrollBar.scrollTop;
            }
          });
    }, [])

    return (
        <div id='down-in-the-dice-main'>
            {/*_____________________NAVBAR.JSX_____________________*/}
            <div className="nav" ref={fixedElem}><NavBar topInt={myScrollbar.current.scrollTop} /></div>

            <div id="DITD-header">

                <div id="DITD-title-container">
                    <div id="DITD-title" className="header-title" ref={addToRefs}>DOWN IN THE DICE</div>
                </div>
            </div>


        </div>
    )
}

export default DownInTheDice;