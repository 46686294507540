import React, { useEffect } from "react";
import { useState, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

import NavBar from "../../components/navbar/NavBar";
import "./gallery.css";

import Scrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import { async } from "@firebase/util";
import { getDocs, collection, query, limit, orderBy, where } from "firebase/firestore";
import { projectFirestore } from "../../fireconfig";

gsap.registerPlugin(ScrollTrigger);

function Gallery() {
    //PRELOADING
    let loadingScreenLeftRef = useRef(null);
    let loadingScreenRightRef = useRef(null);
    //const [isLoadingComplete, setIsLoadingComplete] = useState(false);

    let revealRefs = useRef([]);
    revealRefs.current = [];
    const addToRefs = (el) => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    }

    useEffect(() => {
        //const tl = gsap.timeline();
        gsap.ticker.lagSmoothing();
        gsap.to(loadingScreenLeftRef.current, { width: 0, duration: 1, ease: 'power1.out', onComplete: function () { BuildTextReveal() } }).delay(2)
        gsap.to(loadingScreenRightRef.current, { width: 0, duration: 1, ease: 'power1.out' }).delay(2);


        function BuildTextReveal() {
            revealRefs.current.forEach((el, index) => {
                gsap.fromTo(el,
                    { opacity: 0, y: -20, ease: 'power3.inOut' },
                    {
                        opacity: 1, y: 0, ease: 'power3.inOut', duration: 1,
                        scrollTrigger: { trigger: el, id: `section-${index + 1}`, start: 'top bottom', toggleActions: 'play reset play reset', markers: false }
                    }
                )
            })
        }


    }, [])

    // SCROLL
    const myScrollbar = useRef(Scrollbar);

    const fixedNav = useRef(null);

    useEffect(() => {
        Scrollbar.use(OverscrollPlugin);
        var bodyScrollBar = Scrollbar.init(document.body, {
            damping: 0.07,
            plugins: { overscroll: {} | false },
        });
        myScrollbar.current = bodyScrollBar;
        // fixed element
        bodyScrollBar.addListener(function (status) {
            fixedNav.current.style.top = status.offset.y + 'px';
        })
        //
        bodyScrollBar.setPosition(0, 0);
        bodyScrollBar.track.xAxis.element.remove();
        ScrollTrigger.scrollerProxy("body", {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });
    }, [])

    //Fetch images

    const [database, setDatabase] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [filter, setFilter] = useState([""]);


    // toggle button
    const [btnToggle1, setBtnToggle1] = useState(false);
    const [btnToggle2, setBtnToggle2] = useState(false);
    const [btnToggle3, setBtnToggle3] = useState(false);
    const [btnToggle4, setBtnToggle4] = useState(false);
    const [btnToggle5, setBtnToggle5] = useState(false);
    const [btnToggle6, setBtnToggle6] = useState(false);
    const [isDesc, setIsDesc] = useState(true);

    const handleFilterToggle = (btnToggle, setBtnToggle, filterArray, setFilterArray, filterString) => {

        if (!btnToggle) {
            setFilterArray(filterArray => [...filterArray, filterString]);
        } else {
            setFilterArray(filterArray => filterArray.filter(each => each != filterString));
        }
        setBtnToggle(!btnToggle);
    }

    // query docs 
    useEffect(() => {
        const fetchQuery = async () => {
            try {
                const buildlist = (que) => {
                    getDocs(que).then((snapshot) => {
                        let list = [];
                        snapshot.forEach((child) => {
                            list.push({ id: child.id, ...child.data() });
                        });
                        setDatabase(list);
                    });
                }

                if (isDesc) {

                    if (filter.length === 1) {
                        const que = await query(collection(projectFirestore, "images"), orderBy("lmd", "desc"), limit(100));
                        buildlist(que);
                    } else {
                        const que = await query(collection(projectFirestore, "images"), where("tags", "array-contains-any", filter), orderBy("lmd", "desc"), limit(100));
                        console.log(filter);
                        buildlist(que);
                    }

                }
                else {
                    if (filter.length === 1) {
                        const que = await query(collection(projectFirestore, "images"), orderBy("lmd"), limit(100));
                        buildlist(que);
                    } else {
                        const que = await query(collection(projectFirestore, "images"), where("tags", "array-contains-any", filter), orderBy("lmd"), limit(100));
                        buildlist(que);
                        console.log(filter);
                    }

                }

            } catch (err) {
                console.log(err);
            }
        };
        fetchQuery();
    }, [filter, isDesc])

    return (
        <div className='gallary'>
            {/*_____________________INTRO SCREEN_____________________*/}
            <div className="loading-screen-left" ref={loadingScreenLeftRef}></div>
            <div className="loading-screen-right" ref={loadingScreenRightRef}></div>

            {/*_____________________NAVBAR.JSX_____________________*/}
            <div id="nav-area" ref={fixedNav}><NavBar data={ myScrollbar.current.scrollTop > window.innerHeight }/></div>
            {/*_____________________HEADER_____________________*/}
            <div id="header">
                <div id="bar-box">
                    <div className="bar" id="bar-1"></div>
                    <div className="bar" id="bar-2"></div>
                    <div className="bar" id="bar-3"></div>
                </div>

                <div id="header-title">
                    <div id="gallary-title" ref={addToRefs}>GALLERY</div>
                </div>
            </div>
            <div className="white-line-area-gallary"><div className="white-line-gallary"></div></div>
            <div id="tag-area">
                <button onClick={() => handleFilterToggle(btnToggle1, setBtnToggle1, filter, setFilter, "#badlands")} style={{ backgroundColor: btnToggle1 ? "cyan" : "gray" }} ref={addToRefs}>badlands</button>
                <button onClick={() => handleFilterToggle(btnToggle2, setBtnToggle2, filter, setFilter, "#bayarea")} style={{ backgroundColor: btnToggle2 ? "cyan" : "gray" }} ref={addToRefs}>bayarea</button>
                <button onClick={() => handleFilterToggle(btnToggle3, setBtnToggle3, filter, setFilter, "#craterlake")} style={{ backgroundColor: btnToggle3 ? "cyan" : "gray" }} ref={addToRefs}>craterlake</button>
                <button onClick={() => handleFilterToggle(btnToggle4, setBtnToggle4, filter, setFilter, "#yellowstone")} style={{ backgroundColor: btnToggle4 ? "cyan" : "gray" }} ref={addToRefs}>yellowstone</button>
                <button onClick={() => handleFilterToggle(btnToggle5, setBtnToggle5, filter, setFilter, "#yosemite")} style={{ backgroundColor: btnToggle5 ? "cyan" : "gray" }} ref={addToRefs}>yosemite</button>
                <button onClick={() => handleFilterToggle(btnToggle6, setBtnToggle6, filter, setFilter, "#test")} style={{ backgroundColor: btnToggle6 ? "cyan" : "gray" }} ref={addToRefs}>test</button>

                <button onClick={() => setIsDesc(!isDesc)} ref={addToRefs}>desc order</button>
            </div>
            <div className="white-line-area-gallary"><div className="white-line-gallary"></div></div>
            {/*_____________________HORIZONAL GRID_____________________*/}
            <div className="grid">
                {database && database.map(data => (
                    <figure className="image-wrap" key={data.id} style={{ flexGrow: (data.width * 200 / data.height), flexBasis: (data.width * 480 / data.height), }}>
                        <i style={{ paddingBottom: (data.height / data.width * 100.0) + '%' }}></i>
                        <img src={data.img} alt="uploaded image" />
                    </figure>
                ))}
            </div>


        </div>
    )
}

export default Gallery;