import React, { useState } from "react";
import { async } from "@firebase/util";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { projectFirestore, projectStorage } from "../../fireconfig";

import "./UploadForm.css";
import { userInputs } from "./motherForm";
import { useEffect } from "react";

const UploadForm = () => {
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);
    const [data, setData] = useState({});
    const [progress, setProgress] = useState(0);

    // photo selection
    const handlePhotoSelection = (e) => {
        const selection = e.target.files;
        const selectionArray = Array.from(selection);

        const list = [];
        const imageMap = selectionArray.map((image) => {
            list.push(image);
        });
        setFiles(list);
    };

    // input fields
    const handleInputField = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setData({ ...data, [id]: value });

        //console.log(Object.keys(data).filter((key) => key.includes("tags")).reduce((cur, key) => {return Object.assign(cur, {[key]: data[key]})}, {}));
    };

    //submit
    const handleData = async (e) => {
        e.preventDefault()
        if (!files[0]) return;

        // tags
        const tags = data["tags"].trim().split(" ");

        // images
        const numberOfFiles = files.length 
        for (let index = 0; index < numberOfFiles; index++) {

            const image = files.shift();
            const reader = new FileReader();
            let width = 0;
            let height = 0;

            reader.readAsDataURL(image);
            reader.onload = (meta) => {
                const image = new Image();
                image.src = meta.target.result;
                image.onload = () => {
                    height = image.height;
                    width = image.width;
                }
            }

            const storgaeRef = ref(projectStorage, image.name);
            const uploadTask = uploadBytesResumable(storgaeRef, image);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const currentprogress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress(currentprogress);
                    switch (snapshot.state) {
                        case "paused":
                            console.log("Upload is paused");
                            break;
                        default:
                            break;
                    }
                }, (err) => {
                    setError(err);
                }, () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        uploadData(downloadURL, tags, width, height, image.lastModifiedDate);
                        setProgress(null);
                    });
                }
            )
        }

    }

    // add to fire storage
    const uploadData = async (url, tags, width, height, lmd) => {

        try {
            await addDoc(collection(projectFirestore, "images"), {
                ...data,
                img: url,
                width: width,
                height: height,
                tags: tags,
                lmd: lmd,
                timeStamp: serverTimestamp()
            });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="image-form">

            <form onSubmit={handleData}>

                <input type="file" onChange={handlePhotoSelection} multiple accept="image/png, image/jpeg, image/webp" />


                {userInputs.map((input) => (
                    <div className="input-field" key={input.id}>
                        <label>{input.labal}</label>
                        <input
                            id={input.id}
                            type={input.type}
                            placeholder={input.placeholder}
                            onChange={handleInputField}
                        />
                    </div>
                ))}
                <button type="submit" disabled={progress || !files[0]}>Submit</button>
                <div className="output">
                    {error && <div className="error">{error}</div>}
                    {progress && <div className="progress-bar" style={{ width: progress + '%' }}></div>}
                </div>


                <div id="image-container">
                    {files[0]
                        ? files.map((image, index) => (<div key={index} className="images"><img id="image-preview" src={URL.createObjectURL(image)} alt="image"></img></div>))
                        : <div />
                    }
                </div>
            </form>
        </div>
    )
}

export default UploadForm;