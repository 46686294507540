import Intro from "./pages/intro/intro";
import Projects from "./pages/projects/projects";
import DownInTheDice from "./pages/projects/DownInTheDice/DownInTheDice";
import Gallery from "./pages/gallery/gallery";
import Dev from "./pages/dev/dev";
import Login from "./pages/login/login";
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { AuthContext } from "./context/AuthContext";
import { useContext } from "react";

import './App.css';

function App() {
  const { currentUser } = useContext(AuthContext);
  const RequireAuth = ({children}) => {
    return currentUser ? children : <Navigate to="/devlogin" />
  }

  return (

    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/">
          <Route index element={<Intro />} />
          <Route  path="devlogin" element={<Login />}> </Route>
          <Route  path="dev" element={<RequireAuth> <Dev /> </RequireAuth>}> </Route>
          <Route  path="projects" element={<Projects />}> </Route>
          <Route  path="down-in-the-dice" element={<DownInTheDice />}> </Route>
          <Route  path="gallery" element={<Gallery />}> </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
